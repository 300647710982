<template>
	<div>
		<div class="row mt-4">
			<h1 class="col-sm-12 text-right">Catálogos</h1>
		</div>

		<DataGrid :config="dataGridConfig" :data="catalogos" @actions="dataGridActions" :select="seleccionar">
			<div class="row">
				<div class="col-sm-2"><button class="btn secondary-btn" @click="modal_catalogo=1">Crear</button></div>
				<div class="col-sm-2"><button class="btn principal-btn" @click="editar_catalogo">Editar</button></div>
				<div class="col-sm-2"><button class="btn danger-btn" @click="eliminar_catalogo">Eliminar</button></div>
				<div class="col-sm-2"><button class="btn warning-btn" @click="obtener_catalogos">Recargar</button></div>
			</div>
		</DataGrid>

		<Modal v-if="modal_catalogo != 0" :options="{close:true}" @close="cancelar_catalogo">
			<form @submit.prevent="guardar_catalogo">
				<div class="title">Catálogo</div>
				<div class="body">
					<div class="row form-group">
						<label for="catalogo_nombre" class="col-form-label col-sm-3">Nombre</label>
						<div class="col-sm-9"><input v-model="catalogo.nombre" type="text" name="catalogo_nombre" id="catalogo_nombre" class="form-control"></div>
					</div>
					<div class="row form-group">
						<label for="catalogo_codigo" class="col-form-label col-sm-3">Código</label>
						<div class="col-sm-9"><input v-model="catalogo.codigo" type="text" name="catalogo_codigo" id="catalogo_codigo" class="form-control"></div>
					</div>
					<div class="row form-group">
						<label for="amenidad_estatus" class="col-form-label col-sm-3">Estatus</label>
						<div class="col-sm-9">
							<select v-model="catalogo.activo" name="amenidad_estatus" id="amenidad_estatus" class="form-control">
								<option value="1">Activo</option>
								<option value="0">Inactivo</option>
							</select>
						</div>
					</div>

					<div class="row form-group">
						<div class="col-sm-12">
							<table class="catalogo_valores">
								<thead>
									<th>#</th>
									<th>Nombre</th>
									<th>Valor</th>
									<th>Estatus</th>
									<th>&nbsp;</th>
								</thead>
								<tbody>
									<tr v-for="(opcion, index) in catalogo.opciones" :key="index">
										<td>{{ index+1 }}</td>
										<td>{{ opcion.nombre }}</td>
										<td>{{ opcion.valor }}</td>
										<td>{{ opcion.activo == 1 ? 'Activo' : 'Inactivo' }}</td>
										<td><span @click="editar_opcion(opcion)">Editar</span> | <span @click="eliminar_opcion(opcion)">Eliminar</span></td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>

					<div class="row form-group">
						<div class="col-sm-4">
							<input v-model="catalogo_valor.nombre" type="text" name="catalogo_nombre_opcion" id="catalogo_nombre_opcion" class="form-control" placeholder="Nombre">
						</div>
						<div class="col-sm-4">
							<input v-model="catalogo_valor.valor" type="text" name="catalogo_valor_opcion" id="catalogo_valor_opcion" class="form-control" placeholder="Valor">
						</div>
						<div class="col-sm-2">
							<select v-model="catalogo_valor.activo" name="catalogo_estatus" id="catalogo_estatus" class="form-control">
								<option value="1">Estatus Activo</option>
								<option value="0">Estatus Inactivo</option>
							</select>
						</div>
						<div class="col-sm-2"><button type="button" class="btn secondary-btn" @click="agregar_valor">{{ !catalogo_valor.id ? 'Agregar' : 'Actualizar' }}</button></div>
					</div>
				</div>
				<div class="footer">
					<div class="row">
						<div class="col-sm-2 offset-sm-8"><button class="btn secondary-btn">Guardar</button></div>
						<div class="col-sm-2"><button class="btn danger-btn" @click="cancelar_catalogo">Cancelar</button></div>
					</div>
				</div>
			</form>
		</Modal>
	</div>
</template>

<script type="text/javascript">
	import api from '@/apps/garantias/api/otros'

	import DataGrid from '@/components/DataGridV2'
	import Modal from '@/components/Modal'

	export default {
		components: {
			DataGrid, Modal
		}
		,data: function() {
			return  {
				dataGridConfig: {
					name: 'amenidades'
					,cols: {
						nombre: 'Cátalogo'
						,codigo: 'Código'
						,activo: 'Estatus'
					}
					,paginator: {
						pagina_actual: 1
						,total_registros: 1
						,registros_por_pagina: 20
					}
					,mutators: {
						activo: function(val) {
							return val == 1 ? 'Activo' : 'Inactivo';
						}
					}
				}
				,options: {
					page: 1
					,order_col: 'id'
					,order_dir: 'desc'
					,limit: 20
					,filters: []
				}
				,seleccionadas: []
				,seleccionar: false
				,catalogos: []
				,catalogo: {
					nombre: null
					,codigo: null
					,activo: 1
					,opciones: []
				}
				,catalogo_valor: {
					nombre: null
					,valor: null
					,valor_org: null
					,activo: 1
				}
				,modal_catalogo: 0
			}
		}
		,mounted: function() {
			// this.obtener_amenidades();
		}
		,methods: {
			dataGridActions: function(tipo, data) {
				if (tipo == 'options') {
					this.options = data;
					this.obtener_catalogos();
				}else {
					this.seleccionadas = data;
					this.seleccionar = true;
				}
			}
			,obtener_catalogos: async function() {
				try {
					let res = (await api.obtener_catalogos(this.options)).data;

					this.catalogos = res.data;
					this.dataGridConfig.paginator.pagina_actual = res.current_page;
					this.dataGridConfig.paginator.total_registros = res.total;
					this.dataGridConfig.paginator.registros_por_pagina = parseInt(res.per_page);
				}catch(e) {
					this.$log.info('Error',e);
					this.$helper.showAxiosError(e,'Error');
				}
			}
			,cancelar_catalogo: function() {
				this.catalogo = {
					valor: null
					,nombre: null
					,activo: 1
					,opciones: []
				}

				this.modal_catalogo = 0;
				this.seleccionar = false;
			}
			,guardar_catalogo: async function() {
				try {
					let regex = /(^[a-z0-9\_]*$)/;
					if (!regex.test(this.catalogo.valor))
						return this.$helper.showMessage('Error','El valor debe ser todo en minusculas, sin espacios ni caracteres especiales con excepción del guion bajo (_)','error','alert');

					if (this.modal_catalogo == 1)
						await api.crear_catalogo(this.catalogo);
					else
						await api.editar_catalogo(this.catalogo.id, this.catalogo);

					this.cancelar_catalogo();
					this.obtener_catalogos();
				}catch(e) {
					this.$log.info('Error',e);
					this.$helper.showAxiosError(e,'Error');
				}
			}
			,eliminar_catalogo: async function() {
				try {
					this.$log.info('seleccionadas',this.seleccionadas.length);

					if (this.seleccionadas.length == 0)
						return this.$helper.showMessage('Error','Debes seleccionar al menos una amenidad a eliminar','error','alert');

					await Promise.all(this.seleccionadas.map(async (catalogo) => {
						await api.eliminar_catalogos(catalogo.id);
					}));

					this.seleccionar = false;
					this.obtener_catalogos();
				}catch(e) {
					this.$log.info('Error',e);
					this.$helper.showAxiosError(e,'Error');
				}
			}
			,editar_catalogo: function() {
				if (this.seleccionadas.length == 0)
					return this.$helper.showMessage('Error','Tienes que seleccionar el catálogo a editar','error','alert');

				this.catalogo = this.seleccionadas[0];

				this.modal_catalogo = 2;
			}
			,agregar_valor: function() {
				if (this.catalogo_valor.nombre.length == 0)
					return this.$helper.showMessage('Error','Debes especificar el nombre de la opción del catálogo','error','alert');

				if (this.catalogo_valor.valor.length == 0)
					return this.$helper.showMessage('Error','Debes especificar el valor de la opción del catálogo','error','alert');

				let encontrado = false;
				//Verificamos si el nombre no se repite
				this.catalogo.opciones.forEach(opcion => {
					if (this.catalogo_valor.nombre == opcion.nombre) {
						if (opcion.id && this.catalogo_valor.id) {
							if (opcion.id != this.catalogo_valor.id)
								encontrado = true;
						}else {
							encontrado = true;
						}
					}
				})

				if (encontrado)
					return this.$helper.showMessage('Error','El nombre "'+this.catalogo_valor.nombre+'" ya existe en la lista de opciones','error','alert');

				//Verificamos si el valor ya existe
				this.catalogo.opciones.forEach(opcion => {
					if (this.catalogo_valor.valor == opcion.valor) {
						if (opcion.id && this.catalogo_valor.id) {
							if (opcion.id != this.catalogo_valor.id)
								encontrado = true;
						}else {
							encontrado = true;
						}
					}
				})

				if (encontrado)
					return this.$helper.showMessage('Error','El valor "'+this.catalogo_valor.valor+'" ya existe en la lista de opciones','error','alert');

				if (!this.catalogo_valor.valor_org)
					this.catalogo.opciones.push(this.catalogo_valor);
				else {
					for(let i=0; i<this.catalogo.opciones.length; i++) {
						if (this.catalogo.opciones[i].valor == this.catalogo_valor.valor_org) {
							this.catalogo.opciones[i] = this.catalogo_valor;
						}
					}
				}

				this.catalogo_valor = {
					nombre: null
					,valor: null
					,valor_org: null
					,activo: 1
				}
			}
			,editar_opcion: function(opcion) {
				this.catalogo_valor = this.$helper.clone(opcion);
				this.catalogo_valor.valor_org = opcion.valor;
			}
			,eliminar_opcion: function(opcion) {
				let opciones = [];

				this.catalogo.opciones.forEach(valor_opcion => {
					if (valor_opcion.valor != opcion.valor )
						opciones.push(valor_opcion);
				})

				this.catalogo.opciones = opciones;
			}
		}
	}
</script>

<style lang="scss">
	.catalogo_valores {
		width: 100%;

		thead {
			color: #fff;

			th {
				background-color: #333333;
				padding: 7px 10px;
			}

			th:first-child {
				width: 3%;
			}

			th:last-child {
				width: 12%;
			}
		}

		tbody {
			tr {
				td {
					padding: 2px 10px;

					span {
						cursor: pointer;
					}

					span:hover {
						color: red;
					}
				}
			}

			tr:nth-child(2n+0) {
				background-color: #E6E6E6;
			}
		}
	}
</style>